import React from "react"
import "./responsibility-header.scss"
import { ResponsibilityHeaderProps } from "../../types/components"

const ResponsibilitiesHeader: React.FC<ResponsibilityHeaderProps> = ({
  children,
  banner,
}) => {
  return (
    <div
      className="reasponsibilities-header"
      style={{ backgroundImage: `url(${banner?.sourceUrl})` }}
    >
      <div className="reasponsibilities-header__wrapper container--xl">
        <div className="reasponsibilities-header__elements-left" />
        <div className="reasponsibilities-header__elements-right">
          {children}
        </div>
      </div>
    </div>
  )
}

export default ResponsibilitiesHeader
