import React from "react"
import { graphql, PageProps } from "gatsby"
import { ResponsibilitiesPageProps } from "../types/pages"
import PageLayout from "../layouts/page-layout"
import ResponsibilitiesHeader from "../components/responsibility/responsibility-header"
import { isBrowser } from "../utils/constants"
import CountUp from "react-countup"
import ResponsibilityBody from "../components/responsibility/responsibility-body"
import { ImgProps } from "../types/shared-singulars"

type ParagraphProps = {
  contentTitle: string
  title: string
}

type CountUpElementProps = {
  description: string
  title: number
  icon: ImgProps
  decimals: number
  titleString?: string
  start: number
}

const Responsibilities: React.FC<PageProps<ResponsibilitiesPageProps>> = ({
  data,
}) => {
  const { banner, responsibilitiescontent } =
    data?.PAGE_CONTENT?.ourResponsibilityOptions

  const elements = [
    {
      description: banner?.responsibilities[0]?.description,
      title: Number(banner?.responsibilities[0]?.title),
      icon: banner?.responsibilities[0]?.icon,
      decimals: 0,
      start: 1900,
    },
    {
      description: banner?.responsibilities[1]?.description,
      title: Number(banner?.responsibilities[1]?.title),
      icon: banner?.responsibilities[1]?.icon,
      decimals: 0,
      start: 1950,
    },
    {
      description: banner?.responsibilities[2]?.description,
      title: Number(banner?.responsibilities[2]?.title),
      titleString: "mil",
      icon: banner?.responsibilities[2]?.icon,
      decimals: 1,
      start: 0,
    },
  ]

  return (
    <PageLayout seo={data?.PAGE_METADATA?.seo}>
      <ResponsibilitiesHeader banner={banner?.backgroundImage}>
        <h1 className="container--xl">{data?.PAGE_METADATA?.title}</h1>
        {banner?.responsibilities &&
          elements?.map((item: CountUpElementProps, index: number) => (
            <div
              key={index}
              className="reasponsibilities-header__elements-right__item"
            >
              <div
                className="count-up"
                data-aos="fade-up"
                data-aos-delay={(index + 1) * 100}
                data-aos-easing="ease-out"
              >
                {item.icon && (
                  <img
                    alt={item.icon.altText}
                    srcSet={
                      item.icon.srcSet ? item.icon.srcSet : item.icon.sourceUrl
                    }
                    sizes={item.icon.sizes}
                    className="count-up__icon"
                  />
                )}
                <div className="count-up__content">
                  <div className="count-up__content__node-wrapper">
                    {isBrowser() && (
                      <CountUp
                        className="count-up__content__node"
                        start={item?.start}
                        end={item?.title}
                        duration={5}
                        useEasing={true}
                        decimals={item.decimals}
                        decimal="."
                      />
                    )}
                  </div>
                  <h6>{item.description}</h6>
                </div>
              </div>
            </div>
          ))}
      </ResponsibilitiesHeader>
      <ResponsibilityBody>
        {responsibilitiescontent &&
          responsibilitiescontent.map((item: ParagraphProps, index: number) => (
            <div key={index} className="responsibility-body__paragraph">
              <div className="responsibility-body__paragraph__half half--left">
                {item.title && <span>{item.title}</span>}
              </div>
              <div
                className="responsibility-body__paragraph__half half--right"
                dangerouslySetInnerHTML={{ __html: item.contentTitle }}
              />
            </div>
          ))}
      </ResponsibilityBody>
    </PageLayout>
  )
}

export const query = graphql`
  query ($uri: String!, $locale: String!) {
    PAGE_METADATA: wpPage(
      uri: { eq: $uri }
      locale: { locale: { eq: $locale } }
    ) {
      id
      slug
      title
      link
      locale {
        locale
      }
      seo {
        metaDesc
        metaKeywords
        canonical
        opengraphDescription
        opengraphTitle
        opengraphType
        title
        twitterDescription
        twitterTitle
      }
    }
    PAGE_CONTENT: wpPage(
      uri: { eq: $uri }
      locale: { locale: { eq: $locale } }
    ) {
      ourResponsibilityOptions {
        banner {
          backgroundImage {
            altText
            sizes
            sourceUrl
            srcSet
          }
          responsibilities {
            title
            description
            icon {
              altText
              sizes
              sourceUrl
              srcSet
            }
          }
        }
        responsibilitiescontent {
          contentTitle
          title
        }
      }
    }
  }
`

export default Responsibilities
