import React from 'react';
import './responsibility-body.scss';
import { ResponsibilityBodyProps } from '../../types/components';

const ResponsibilityBody: React.FC<ResponsibilityBodyProps> = ({children}) => {
    return (
        <div className="responsibility-body">
            <div className="responsibility-body__wrapper container--xl">
                {children}
            </div>
        </div>
    );
};

export default ResponsibilityBody;